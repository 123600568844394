/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'
import axios from 'axios'
import Cookies from 'js-cookie'

import { Formik, FormikHelpers, FormikProps } from 'formik'
import { z } from 'zod'
import { toFormikValidate } from 'zod-formik-adapter'

import emitGTMEvent from 'utils/emitGTMEvent'

import { CheckBox } from 'components/atoms/CheckBox'
import { Input } from 'components/atoms/Input'
import { Button, ButtonLine } from 'components/atoms/Button'
import { Text } from 'components/atoms/Typography'

export const ContactFormContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);

  ${media.xxl.max} {
    padding: 40px 24px;
  }
  ${media.sm.max} {
    margin: 0px auto;
    padding: 20px;
  }
  ${media.xs.max} {
    width: 100%;
    min-width: 0px;
  }
`

const StyledForm = styled.form`
  margin: 0px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Information = styled.p`
  font-size: 10px;
  font-weight: 400px;
  line-height: 14px;
`

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`

const MoreButton = styled.a`
  cursor: pointer;
  padding-left: 7px;

  font-weight: 400;
  font-size: 10px;
  text-decoration: none;

  color: #3ca243;
  background-color: transparent;
  border: none;
`

const StyledButton = styled(Button)`
  margin-top: 12px;
`

type FormValues = {
  name: string
  email: string
  phone: string
  emailOffers: boolean
  phoneOffers: boolean
}

type ContactFormProps = {
  formInfo: {
    text: string
    error: boolean
  }
  setFormInfo: React.Dispatch<
    React.SetStateAction<{
      text: string
      error: boolean
    }>
  >
  flatNumber: string
}

const schema = z.object({
  name: z.string().nonempty('Wymagane'),
  email: z.string().email('Nieprawidłowy adress E-mail').nonempty('Wymagane'),
  phone: z.string(),
  emailOffers: z
    .boolean()
    .refine((value) => value === true, { message: 'Wymagane' }),
  phoneOffers: z.boolean(),
})

declare const window: Window & { previousPath: string }

export const ContactForm: React.FC<ContactFormProps> = ({
  formInfo,
  setFormInfo,
  flatNumber,
}) => {
  const initialValues: FormValues = {
    name: '',
    email: '',
    phone: '',
    emailOffers: false,
    phoneOffers: false,
  }
  const informations =
    'Informujemy, że w przypadku wyrażenia zgody w jednym lub w kilkuwyżej wymienionych celach, przysługuje Pani/Panu prawo do cofnięcia <Space> zgody w dowolnym momencie, bez wpływu na zgodność zprawem przetwarzania, którego dokonano na podstawie zgody przedjej cofnięciem. <MoreButton>Więcej</MoreButton>'

  // TODO: Create a custom hook for expanding text
  const [expandedInfo, setExpandedInfo] = React.useState(false)

  const handleToggleExpandInfo = () => {
    setExpandedInfo(!expandedInfo)
  }

  const renderInformation = () => {
    const spaceIndex = informations.indexOf('<Space>')

    if (spaceIndex !== -1 && !expandedInfo) {
      const shortenedInformation = `${informations.substring(0, spaceIndex)}...`
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: shortenedInformation }} />
          <MoreButton onClick={handleToggleExpandInfo}>Więcej</MoreButton>
        </>
      )
    }
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: informations }} />
        {expandedInfo && (
          <MoreButton onClick={handleToggleExpandInfo}>Pokaż mniej</MoreButton>
        )}
      </>
    )
  }

  return (
    <ContactFormContainer>
      <Text fontSize={18} fontWeight={700}>
        Zapytaj o cenę
      </Text>

      <Formik
        initialValues={initialValues}
        validate={toFormikValidate(schema)}
        onSubmit={(
          values,
          { setSubmitting, resetForm }: FormikHelpers<FormValues>
        ) => {
          ;(async () => {
            try {
              setSubmitting(true)

              const formData = new FormData()

              formData.append('name', values.name)
              formData.append('email', values.email)
              formData.append('phone', values.phone)
              formData.append('consent1', values.emailOffers.toString())
              formData.append('consent2', values.phoneOffers.toString())
              formData.append('flat', flatNumber)
              // youlead params
              formData.append('formName', 'contact')
              formData.append('url', window.location.href)
              formData.append('refereer', window.previousPath)
              if (Cookies.get('ylid')) {
                formData.append('ylid', String(Cookies.get('ylid')))
              }
              if (Cookies.get('ylutm')) {
                formData.append('ylutm', String(Cookies.get('ylutm')))
              }
              if (Cookies.get('ylssid')) {
                formData.append('ylssid', String(Cookies.get('ylssid')))
              }

              const { data } = await axios.post(
                'https://cmsmiasteczko.indigital.guru/mailer/mailer-main.php',
                formData
              )

              emitGTMEvent({
                event: 'form_sent',
                lead_id: data.leadId,
              })

              if (data.yl.returnCode) {
                let returnScript = data.yl.returnCode.replaceAll(
                  '<script type="text/javascript">',
                  ''
                )
                returnScript = returnScript.replaceAll('<script>', '')
                returnScript = returnScript.replaceAll('</script>', '')
                // eslint-disable-next-line
                eval(returnScript)
              }

              setSubmitting(false)

              setFormInfo({
                text: 'Nasz konsultant skontaktuje się z Tobą.',
                error: false,
              })

              const pixel = document.createElement('img')
              pixel.src =
                'https://detionsacialers.com/conversion.gif?cid=OPTIONAL'
              pixel.width = 1
              pixel.height = 1
              pixel.style.display = 'none'
              document.body.appendChild(pixel)

              setTimeout(() => {
                setFormInfo({ text: '', error: false })
                resetForm()
              }, 5000)
            } catch (err) {
              setSubmitting(false)
              setFormInfo({ text: 'Coś poszło nie tak!', error: true })
              setTimeout(() => {
                setFormInfo({ text: '', error: false })
              }, 5000)
            }
          })()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: FormikProps<FormValues>) => (
          <StyledForm onSubmit={handleSubmit}>
            <Input
              label="Imię i nazwisko"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name}
              touched={touched.name}
            />
            <Input
              label="Adres e-mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              touched={touched.email}
            />
            <Input
              label="Numer telefonu"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone}
              touched={touched.phone}
            />
            <CheckBoxContainer>
              {/* Use <Space> as a break point for "Show more" */}
              <CheckBox
                label="Chcę otrzymywać na mój <b>adres e-mail</b>  od Aurec Home Holding sp. z o.o. <Space> oferty sprzedaży i najmu lokali oraz innych związanych z tym usług oferowanych przez Aurec Home Holding sp. z o.o. oraz inne podmioty z Grupy Kapitałowej Aurec Capital"
                name="emailOffers"
                value={values.emailOffers}
                error={errors.emailOffers}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CheckBox
                label="Chcę otrzymywać na mój <b>numer telefonu</b> (połączenia telefoniczne, wiadomości SMS/MMS) od Aurec Home Holding sp. z o.o. <Space> oferty sprzedaży i najmu lokali oraz innych związanych z tym usług oferowanych przez Aurec Home Holding sp. z o.o. oraz inne podmioty z Grupy Kapitałowej Aurec Capital"
                name="phoneOffers"
                value={values.phoneOffers}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Information>{renderInformation()}</Information>
            </CheckBoxContainer>
            <StyledButton TextButton2 noMaxWidth disabled={isSubmitting}>
              <ButtonLine>Zapytaj o cenę</ButtonLine>
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </ContactFormContainer>
  )
}
